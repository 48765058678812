@import '../fonts/stylesheet.css';

svg {
	max-width: 100%;
	max-height: 100%;
}

html {
	scroll-behavior: smooth;

	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	::-webkit-scrollbar-thumb {
		background: #07114a;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #02082c;
	}
	::-webkit-scrollbar-track {
		background: #ff000000;
	}

	body {
		margin: 0;
		overflow-x: hidden;
		direction: rtl;
		font-family: 'Ploni';
		background-color: #ecf5f9;

		#my-activities {
			scroll-margin-top: min(160px, 16vw);
		}

		*,
		*::after,
		*::before {
			//   transition: all ease-out 200ms;
			box-sizing: border-box;
			font-family: 'Ploni';
		}

		a {
			text-decoration: none;
			color: inherit;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			display: block;
		}

		//position
		.relative {
			position: relative;
		}

		.absolute {
			position: absolute;
		}

		.fixed {
			position: fixed;
		}

		//flex
		.flex {
			display: flex;
		}

		.column {
			flex-direction: column;
		}

		.align-center {
			align-items: center;
		}

		.align-start {
			align-items: flex-start;
		}

		.align-end {
			align-items: flex-end;
		}

		.align-base {
			align-items: baseline;
		}

		.justify-center {
			justify-content: center;
		}

		.justify-start {
			justify-content: flex-start;
		}

		.justify-end {
			justify-content: flex-end;
		}

		.justify-between {
			justify-content: space-between;
		}

		.wrap {
			flex-wrap: wrap;
		}

		.nowrap {
			flex-wrap: nowrap;
		}

		.row {
			flex-direction: row;
		}

		.row-reverse {
			flex-direction: row-reverse;
		}
		.absolute-center {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		@keyframes rotate {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
	.react-draggable {
		cursor: grab;
	}

	.Yad2PopupSlider {
		.swiper-wrapper {
			height: 100% !important;
			.swiper-slide {
				height: 100%;
			}
		}
	}
}

input[type='number'] {
	&:focus {
		outline: none;
	}
}

.pagination {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	list-style: none;
	gap: 5px;
	padding: unset;

	li {
		button {
			border-radius: 100vw;
			border: none;
			padding: 5px 10px;
			//   background-color: #4950e6;
			//   color: white;
			cursor: pointer;
		}
		&.active {
			button {
				background-color: #4950e6;
				color: white;
			}
		}
	}
}
